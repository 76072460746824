/* $picton-blue: #3190e8;
$curious-blue: #2780dc; */
:root {
  --primaryColor: #333;
}

.varnotify-background {
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 2147483647;
  left: 0;
}
.varnotify-background .notify-mobile {
  display: block;
  position: fixed;
  height: 80px;
  width: 470px;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  right: 470px;
  background: #fff;
  padding-top: 5px;
  padding-left: 15px;
  padding-bottom: 5px;
  padding-right: 20px;
  border-radius: 4px;
}
@media (max-width: 768.98px) {
  .varnotify-background .notify-mobile {
    max-width: 350px;
  }
}
.varnotify-background .check-mark-attr {
  background-image: url("../../images/checkmark1.svg");
  display: block;
  width: 40px;
  height: 40px;
  top: 12px;
  left: 0;
  position: relative;
  background-repeat: no-repeat;
}
@media (max-width: 768.98px) {
  .varnotify-background .check-mark-attr {
    width: 60px;
    height: 60px;
  }
}
.varnotify-background .notify-success-msg {
  font-size: 14px;
  color: #000;
  margin-left: 10px;
  margin-top: 15px;
  text-align: left;
}
@media (max-width: 768.98px) {
  .varnotify-background .notify-success-msg {
    margin-top: 0;
  }
}
.varnotify-background .notify-me-error {
  text-transform: none;
  color: #000;
}