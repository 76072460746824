@import "../scss/variables";
@import "../scss/base/fonts";
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/variables";

.varnotify-background {
    width: 100%;
    padding: 10px;
    background: $trans-black;
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 2147483647;
    left: 0;

    .notify-mobile {
        display: block;
        position: fixed;
        height: 80px;
        width: 470px;
        top: 100px;
        left: 50%;
        transform: translate(-50%, -50%);
        right: 470px;
        background: $white;
        padding-top: 5px;
        padding-left: 15px;
        padding-bottom: 5px;
        padding-right: 20px;
        border-radius: 4px;

        @include media-breakpoint-down(sm) {
            max-width: 350px;
        }
    }

    .check-mark-attr {
        background-image: url("../../images/checkmark1.svg");
        display: block;
        width: 40px;
        height: 40px;
        top: 12px;
        left: 0;
        position: relative;
        background-repeat: no-repeat;

        @include media-breakpoint-down(sm) {
            width: 60px;
            height: 60px;
        }
    }

    .notify-success-msg {
        font-size: 14px;
        color: $black;
        margin-left: 10px;
        margin-top: 15px;
        text-align: left;

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }

    .notify-me-error {
        text-transform: none;
        color: $black;
    }
}
